<template>
<div>
  <DashboardAdmin v-if="$store.state.user !== null && $store.state.user.roles.includes('Admin')" />
  <DashboardMember v-if="$store.state.user !== null && $store.state.user.roles.includes('Therapist')" /> 
  <Footer />
</div>
</template>

<script>
import DashboardMember from "@/components/modules/DashboardMember.vue"
import DashboardAdmin from "@/components/modules/DashboardAdmin.vue"

export default {
  name: 'Dashboard',
  components: { DashboardMember, DashboardAdmin },
  data () {
    return {
      admin: false
    }
  },
  created: function () {
    this.$store.state.loadingProgress = false
      this.$store.state.appSection = 'appUser'
      console.log("User Roles", this.$store.state.user.roles);
    if (this.$store.state.user === null) {
      this.signOut()
    }
  },
}
</script>
