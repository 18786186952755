<template>
  <div class="container-fluid wrapper-medium mt-5">
    <div class="row mb-3">
      <div class="col-12 text-center">
        <h2 class="anim-load1">
          Welcome to the <span class="text-info">Admin</span> Dashboard
        </h2>
      </div>
    </div>

    <div class="row mt-5 mb-5 justify-content-md-center">
      <div class="col-12 col-md-4 mb-3">
        <b-link class="box-btn" to="/organisations-management/">
          <span class="icon icon-sitemap"></span>
          <h3>Organisations management</h3>
          <span class="btn btn-warning">Manage</span>
        </b-link>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <b-link class="box-btn" to="/admin-management">
          <span class="icon icon-lock-open"></span>
          <h3>Admin users management</h3>
          <span class="btn btn-warning">Manage</span>
        </b-link>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <b-link class="box-btn" v-on:click="signOut()">
          <span class="icon icon-logout"></span>
          <h3>Sign out</h3>
          <p>Securely sign out of your account</p>
          <span class="btn btn-warning">Sign out</span>
        </b-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'DashboardAdmin',
  data () {
    return {
    }
  }
}
</script>
