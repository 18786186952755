<template>
<div v-if="userData !== null">
  <div class="container-fluid wrapper-medium mt-3">
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="anim-load1 mt-2">Hello {{userData.title}} {{userData.lastName}}!</h2>
        <h6 v-if="userData.organisationName">{{userData.organisationName}}</h6>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium">

    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <Credits :creditsAv="creditsAv" :displayInfo="true" />
          </div>
          <div class="col-md-4 mt-3 mt-md-0" v-if="userData.organisationAdmin">
            <h4>Manage your organisation members</h4>
            <b-link class="btn btn-warning" to="/members-management">Manage members</b-link>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <h4 class="mb-3">Parent & Child profiles</h4>
            <ListViewer 
              :items="userProfiles" 
              :perPage="5" 
              btnLabel="View" 
              display="parentLastName,parentFirstName,childFirstName"
              urlSlug="/profile/"
              urlTarget="id"
              sortBy="parentLastName"
              :showSearch="true"
            />
          </div>
          <div class="col-md-4 border-md-left">
            <h4>Add new family</h4>
            <p>Add new family & send new questionnaire.</p>
            <b-link class="btn btn-warning btn-block" to="/profile/invite" :disabled="creditsAv < 1">Add new family</b-link>
          </div>

        </div>
      </div>
    </div>



  </div>
</div>

</template>

<script>
import ListViewer from '@/components/modules/ListViewer';
import Credits from '@/components/modules/Credits';
import accountDetails from '@/mixins/accountDetails'

export default {
  name: 'DashboardMember',
  components: {
    ListViewer, Credits
  },
  mixins: [accountDetails],
  data () {
    return {
      userProfiles: []
    }
  },

  computed: {
    isOrganisationAdmin() {
          return (this.$store.state.user !== null && (this.$store.state.user.roles.includes('OrgAdmin') || this.$store.state.user.roles.includes('OrgSuperAdmin'))) ? true : false
    }
  },

  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    await Promise.all([this.getOrganisationCredits(), this.getParentProfiles()]); // methods in mixin
    this.$store.state.loadingProgress = false
  },

  methods: {
    getParentProfiles () {
      this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireProfilesGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          this.userProfiles = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.showError('Something went wrong. Please try again.')
        })      
    }
  }
}
</script>
