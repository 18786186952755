<template>
  <div v-if="creditsAv !== null">
    <h5 class="mb-0">Available credits: <span class="badge badge-pill" v-bind:class="badgeClass">{{creditsAv}}</span></h5>
    <p v-if="displayInfoComp" class="mt-2 mb-0">Each newly created questionnaire costs 1 credit. <br>Contact <a class="link" :href="'mailto:' + supportEmail + '?subject=Credits request'" target="_blank">{{supportEmail}}</a> to request more credits.</p>
    <p v-if="insufficient" class="mt-2 mb-0">You can't send new questionnaires. <br>Contact <a class="link" :href="'mailto:' + supportEmail + '?subject=Credits request'" target="_blank">{{supportEmail}}</a> to request more credits.</p>
  </div>
</template>

<script>
export default {
  name: 'Credits',
  props: {
    creditsAv: {required: true},
    displayInfo: {type: Boolean, required: true}
  },
  data () {
    return {
      supportEmail: this.$uiValues.supportEmail,
    }
  },
  computed: {
    badgeClass() {
      return this.creditsAv > 0 ? 'badge-info' : 'badge-danger'
    },
    insufficient() {
      return this.creditsAv < 1 ? true: false
    },
    displayInfoComp() {
      return (this.displayInfo && !this.insufficient) ? true : false
    }
  }
}
</script>