<template>
  <div>
    <div class="input-group mb-2" v-if="showSearch">
      <input v-model="searchTerm" type="text" class="form-control" placeholder="Search" aria-label="Search user">
      <div class="input-group-append">
        <button class="btn btn-link" type="button" v-on:click="searchReset()">Show all</button>
      </div>
    </div>

    <b-pagination v-if="itemsComp.length > perPage"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="list-users"
      size="sm"
    ></b-pagination>

    <ul class="list-unstyled" id="list-users" v-if="itemsComp.length > 0">
      <li v-for="item in itemsForList" v-bind:key="item.id">
        <b-link 
          :title="btnLabel" class="border-bottom d-flex justify-content-between py-2 btn-list" 
          :to="urlSlug + item[urlTargetProp]"
        >
          <h6 class="mb-0">{{item.display}}</h6>
          <div>
            <span class="btn btn-warning btn-sm">{{btnLabel}}</span>
          </div>
        </b-link>
        <b-link 
            v-if="addBtn"
            :title="addBtnLabel" class="border-bottom d-flex justify-content-center py-2 btn-list" 
            :to="addUrlSlug + item[addUrlTargetProp]"
        >
            <div>
                <span class="btn btn-warning btn-sm">{{addBtnLabel}}</span>
            </div>
        </b-link>
      </li>
    </ul>
    <p v-if="itemsComp.length < 1">Nothing to display</p>
  </div>
</template>

<script>
export default {
  name: 'ListViewer',
  props: {
    items: {type: Array, required: true},
    perPage: {type: Number, required: false, default: 10},
    btnLabel: {type: String, required: false, default: 'View'},
    display: {type: String, required: true},
    urlSlug: {type: String, required: true},
    urlTarget: {type: String, required: true},
    sortBy: {type: String, required: true},
    showSearch: { type: Boolean, required: false, default: true },
    addBtn: { type: Boolean, required: false },
    addBtnLabel: { type: String, required: false },
    addUrlSlug: { type: String, required: false },
    addUrlTargetProp: {type: String, required: false}
  },
  data () {
    return {
      currentPage: 1,
      searchTerm: ''
    }
  },
  computed: {
    displayComp() {
      return this.display.split(',')
    },
    itemsComp() {
      var itemsNew = this.items

      // Search against top level item properties
      if (this.searchTerm !== '') {
        var itemsFiltered = itemsNew.filter(o =>
          Object.keys(o).some(k => {
            return typeof o[k] === 'string' &&  o[k].toLowerCase().includes(this.searchTerm.toLowerCase())
          }));
        itemsNew = itemsFiltered
      }

      // Add display prop based on display array of string and sort alphabetically
      var mappedItems = itemsNew.map(item => {
        var display = this.displayComp.map((x) => (item[x])).join(', ')
        return ({
          ...item, 
          display: display
        })
      }).sort((a, b) => {
        return a[this.sortBy].localeCompare(b[this.sortBy]);
      })

      return mappedItems
    },
    rows() {
      return this.itemsComp.length
    },
    itemsForList() {
      return this.itemsComp.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
    urlTargetProp() {
      return this.urlTarget
    }
  },
  methods: {
    searchReset: function () {
      this.searchTerm = ''
    }
  },
  watch: {
    search () {
      this.searchTerm !== '' ? this.currentPage = 1 : null
    }
  }
}
</script>