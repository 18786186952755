// Account details mixins

export default {
  methods: {
    async accountPersonalDetailsGet() {
      // Therapist accounts have additional data
      if (this.$store.state.user.roles.includes('Therapist', 'Admin')) {
        return this.$http({
          method: 'get', 
          url: this.$api.AccountPersonalDetailsGet,
          headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
        })
          .then((response) => {
            this.$store.commit('setUserData', response.data)
          })
          .catch((error) => {
            console.error('Error', error)
            this.alertMsgShow('Something went wrong', false)
          })
      } else {
      // Other account roles don't
        return this.$store.commit('setUserData', this.$store.state.user)
      }
    },
    
    async getOrganisationCredits() {
      if (this.$store.state.user.roles.includes('Therapist', 'Admin')) {
        return this.$http({
          method: 'get', 
          url: this.$api.OrganisationCreditsGet,
          headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
        })
          .then((response) => {
            console.log(response)
            this.$store.commit('setOrganisationCredits', response.data)
          })
          .catch((error) => {
            console.error('Error', error)
            this.alertMsgShow('Something went wrong', false)
          })
      }
    }
  },
  computed: {
    userData() {
      return this.$store.state.userData
    },
    creditsAv() {
      return this.$store.state.organisationCredits !== null ? this.$store.state.organisationCredits : null
    }
  },
}